import lazyLoadImages from './components/lazy';

const typography = document.querySelector('.typography');

// init lazy load images
lazyLoadImages();

// add typography functions
if (typography) {
    import('./components/typography').then((script) => {
        script.typography();
    });
}

// resize menu on scroll
window.addEventListener('scroll', () => {
    document.querySelector('header').classList.toggle('scrolled', window.scrollY > 0);
});
